<template>
  <div id="myapp">
    <Header active_tab="assessment"></Header>

    <div class="d-flex justify-content-center">
      <div class="col-11 d-flex justify-content-center">
        <h4>{{ title }}</h4>
      </div>
    </div>

    <div class="row justify-content-around my-5">
      <div class="col mx-5 my-3" v-if="!admin">
        <ShapedHeading title="Rank" color="red" :text="rank"></ShapedHeading>
      </div>
      <div class="col mx-5 my-3" v-if="!admin">
        <ShapedHeading
          title="Percentile"
          color="green"
          :text="percentile"
        ></ShapedHeading>
      </div>
      <div class="col mx-5 my-3">
        <ShapedHeading
          title="Avg Score"
          color="blue"
          :text="avg"
        ></ShapedHeading>
      </div>
      <div class="col mx-5 my-3" v-if="!admin">
        <ShapedHeading
          title="Your Score"
          color="rgba(180,62,100,1)"
          :text="score"
        ></ShapedHeading>
      </div>
      <div class="col mx-5 my-3" v-if="admin">
        <ShapedHeading
          title="Total Score"
          color="rgba(180,62,100,1)"
          :text="total"
        ></ShapedHeading>
      </div>
      <div class="col mx-5 my-3" v-if="admin">
        <ShapedHeading
          title="Total Students"
          color="green"
          :text="num"
        ></ShapedHeading>
      </div>
    </div>

    <div id="chart">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-danger dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Change scale
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" @click="updateGraph(10)">Top 10</a>
          <a class="dropdown-item" href="#" @click="updateGraph(50)">Top 50</a>
          <a class="dropdown-item" href="#" @click="updateGraph(100)"
            >Top 100</a
          >
          <a class="dropdown-item" href="#" @click="updateGraph(500)"
            >Top 500</a
          >
        </div>
      </div>
      <div id="chart-plot"></div>
    </div>

    <div class="row" id="mycontainer">
      <div class="col canvas-container"><canvas id="myChart"></canvas></div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const Header = defineAsyncComponent(() => import("@/components/Header"));
import store from "../store";
const ShapedHeading = defineAsyncComponent(() =>
  import("@/components/ShapedHeading")
);
import { getTest, testStats, testTakers } from "@/Utils/global";

export default {
  name: "Stats",
  components: {
    ShapedHeading,
    Header,
  },
  async mounted() {
    this.user_id = store.state.user.data.uid;

    getTest(this.page_id, this.id).then(async (test) => {
      console.log(test.data());
      this.total = test.data().total;
      this.title = test.data().title;
      this.startTime = test.data().start.seconds;
      let div,
        parts = 10;
      div = (this.total * 1.0) / parts;
      this.histogram_data[0] = 0;
      for (var i = 1; i <= parts; i++) {
        this.histogram_data[i] = 0;
        this.labels[i - 1] = Math.round(div * i * 100) / 100; //Upto 2 decimal places
      }

      //this.labels[parts]=Math.round(div*parts*100)/100;
      var li = [];
      testStats(this.page_id, this.id).then((doc) => {
        if (doc.exists) {
          this.res = doc.data();
          li = this.res.score;
          this.num = li.length;
          this.rank = this.num;
          this.avg = Math.round(this.res.average * 100) / 100;

          testTakers(this.page_id, this.id, this.uid).then((doc) => {
            if (doc.exists) {
              var temp = doc.data();
              this.score = temp.score;
              li.sort(function (a, b) {
                return b - a;
              });
              this.rank = li.indexOf(temp.score, 0) + 1;
              var M = li.lastIndexOf(temp.score, 0) + 1;
              var R = M - this.rank;
              M = this.num - M;
              this.percentile =
                Math.round(((M + 0.5 * R) / this.num) * 10000) / 100;
              for (var i in li) {
                this.histogram_data[
                  Math.max(0, Math.ceil(li[i] / div) - 1)
                ] += 1;
              }
              var ctx = document.getElementById("myChart").getContext("2d");
              var chart = new Chart(ctx, {
                // The type of chart we want to create
                type: "bar",
                // The data for our dataset
                data: {
                  labels: this.labels,
                  datasets: [
                    {
                      label: "Score Pattern",
                      backgroundColor: "rgb(255, 99, 132)",
                      borderColor: "rgb(255, 99, 132)",
                      borderWidth: 2,
                      hoverBackgroundColor: "rgba(255,99,132,0.4)",
                      hoverBorderColor: "rgba(255,99,132,1)",
                      data: this.histogram_data,
                    },
                  ],
                },

                // Configuration options go here
                options: {
                  responsive: true,
                  aspectRatio: 1,
                },
              });
            }
          });
          this.createGraphData();
        }
      });
    });
    store.state.loading = false;
  },
  data() {
    return {
      num: 0,
      avg: 0,
      percentile: 0,
      labels: [],
      rank: 0,
      histogram_data: [],
      admin: store.getters.user.data.admin,
      uid: store.getters.user.data.uid,
      total: "",
      title: "",
      score: 0,
      data: [],
    };
  },
  methods: {
    createGraphData: async function () {
      this.data = this.res.score.sort(function (a, b) {
        return b - a;
      });
      this.plotGraph(this.data);
    },
    plotGraph(data, limit) {
      var options = {
        chart: {
          width: "100%",
          height: 380,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "10%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#337ab7"],
        },
        series: [
          {
            name: "marks",
            data: data.slice(0, limit),
          },
        ],
        xaxis: {
          categories: ["100%", "80%", "60%", "40%", "20%"],
        },
        legend: {
          position: "right",
          verticalAlign: "top",
          containerMargin: {
            left: 35,
            right: 60,
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        noData: {
          text: "Loading...",
        },
      };

      var chart = new ApexCharts(
        document.getElementById("chart-plot"),
        options
      );

      chart.render();
    },

    sort_questions(a, b) {
      if (parseInt(a.id) < parseInt(b.id)) return -1;
      else return 1;
    },
    updateGraph(amt) {
      document.getElementById("chart-plot").firstChild.remove();
      console.log(amt);
      this.plotGraph(this.data, amt);
    },
  },
  props: ["id", "page_id"],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap");

#myapp {
  position: absolute;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  /*background:linear-gradient(330deg, rgba(255,255,255,1) 0%, rgba(250,243,243,1) 53%, rgba(174,187,209,1) 100%, rgba(251,251,251,1) 100%);*/
  background-color: #fdf9fe;
}

h4 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 40px;
  font-weight: 700;
  opacity: 0.8;
  margin-top: 10px;
}

.canvas-container {
  padding-top: 50px;
  padding-bottom: 100px;
  width: 400px !important;
  overflow: auto;
  margin-right: 20px;
  margin-left: 20px;
  white-space: nowrap;
  /*border: 1px solid black;*/
  background-color: #dedede;
}

#mycontainer {
  /* height:50% ;
    width:60%; */
  margin: auto;
}

#myChart {
  background-color: white;
  border: 1px dotted red;
  max-height: 400px !important;
  max-width: 400px !important;
  margin: auto;
}
#chart {
  max-width: 800px;
  margin: 35px auto;
  opacity: 0.9;
}

.chart {
  margin: 0 auto;
  display: table;
}
</style>
